//打开全屏
export const requestFullscreen = () => {
    const docElm = document.documentElement
    if (docElm.requestFullscreen) {
        docElm.requestFullscreen()
    } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen()
    } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen()
    } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen()
    }
}
//退出全屏
export const exitFullScreen = () => {
    if (document.exitFullscreen) {
        document.exitFullscreen()
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
    } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
    }
}
//回到顶部
export const toTop = (el) => {
    el.scrollTop = 0
}
//回到底部
export const toBottom = (el) => {
    el.scrollTop = el.scrollHeight
}
export function setDeepValue(obj, path, value) {
    const keys = path.split('.')
    let temp = obj

    for (let i = 0; i < keys.length - 1; i++) {
        let key = keys[i]
        if (!temp[key]) {
            temp[key] = {}
        }
        temp = temp[key]
    }

    temp[keys[keys.length - 1]] = value
}
