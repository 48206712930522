/**
 * @file 设置组件使用的网络接口相关
 */

import axios from 'axios'


import * as tools from '@/tools/tools.js'




/**
 * @description 智能云打印控件 获取打印机列表接口
 */
export async function getPrintCloudListApi() {

    const result = await axios({
        url: `http://91110105ma00ejf78j.d4w.cc:8848/list`,
    });

    console.log(':8848/list result', result);

    if (result.status == 200) {
        if (tools.objectGet(result.data, 'code') == 0) {
            return {
                $successData: true,
                list: tools.objectGetArray(result.data, 'data').map(i => {
                    const name = i.print_name;
                    return {
                        id: name,
                        name,
                        default: i.default == 1,
                        index: i.index,
                    }
                })
            }
        }
    }
    return false;
}



/**
 * @description 智能云打印控件 打印html
 */
export async function printHtmlApi(data) {

    const result = await axios({
        method: 'post',
        url: `http://91110105ma00ejf78j.d4w.cc:8848/print`,
        data
    });

    console.log(':8848/print result', result);

    if (result.status == 200) {
        const code = tools.objectGet(result.data, 'code');

        if (code == 0) {
            return {
                $successData: true,
            }
        }
        return code;
    }
    return result.status;
}


