<template>
	<div class="root-page">
		<div class="bg-image-wrap">
			<div class="bg-color-wrap">
				<div class="show-title">
					<div class="welcome-text">欢迎登录</div>
					<div class="title-wrap"><div class="title-text">windows分拣管理系统</div></div>
				</div>
			</div>
		</div>
		<div class="form-wrap">
			<div class="form-box">
				<div class="input-group-wrap">
					<div class="input-wrap">
						<div class="icon-wrap flex-center"><div class="icon-user iconfont"></div></div>
						<keyboard-input
							class="input-dom"
							field="user"
							:value="user"
							@inputChange="formItemChange"
							@keyupEnter="loginFn"
							placeholder="请输入账号"
						></keyboard-input>
					</div>

					<div class="input-wrap">
						<div class="icon-wrap flex-center"><div class="icon-mima iconfont"></div></div>
						<keyboard-input
							class="input-dom"
							field="pass"
							:value="pass"
							@inputChange="formItemChange"
							placeholder="请输入密码"
							@keyupEnter="loginFn"
						></keyboard-input>
					</div>

					<div class="input-wrap">
						<div class="icon-wrap flex-center"><div class="icon-wangzhi iconfont"></div></div>
						<keyboard-input
							class="input-dom"
							field="host"
							:value="host"
							@inputChange="formItemChange"
							placeholder="请输入域名"
							@keyupEnter="loginFn"
						></keyboard-input>
					</div>
				</div>

				<el-button
					type="success"
					:loading="loginBtnLoading"
					round
					class="commit-btn border-radius"
					@click="loginFn"
				>
					登录
				</el-button>
			</div>
		</div>

		<!-- 设置按钮 -->
		<div class="setting-fixed" @click="openSystemSetFn"><i class="el-icon-setting icon"></i></div>

		<system-set ref="systemSetRef"></system-set>
	</div>
</template>

<script>
import * as tools from '@/tools/tools.js';

import * as utils from '@/tools/utils.js';

import * as loginApis from './login-apis.js';

import keyboardInput from '@/components/simpleKeyboard/keyboard-input.vue';

// 系统设置组件
import systemSet from '@/components/set-comps/system-set.vue';

export default {
	name: 'loginPage',
	components: {
		systemSet,
		keyboardInput
	},
	data() {
		return {
			loginBtnLoading: false,
			user: '',
			pass: '',
			host: ''
		};
	},
	mounted() {
		this.host = this.getHostFn();

		const user = tools.assertString(localStorage.getItem('systemLoginUser')),
			pass = tools.assertString(localStorage.getItem('systemLoginPass'));
		if (user) {
			this.user = user;
		}
		if (pass) {
			this.pass = pass;
		}
	},

	methods: {
		// 打开系统设置弹框
		openSystemSetFn() {
			this.$refs.systemSetRef.showFn();
		},

		// 校验数据 并返回登录所需参数
		checkDataFn() {
			const user = tools.assertString(this.user),
				pass = tools.assertString(this.pass),
				host = tools.assertString(this.host);

			if (!user) {
				this.$message.warning('请输入账号');
				return false;
			}

			if (!pass) {
				this.$message.warning('请输入密码');
				return false;
			}

			if (!host) {
				this.$message.warning('请输入域名');
				return false;
			}

			const checkHostRes = utils.checkHostFn(host);

			if (!checkHostRes) {
				this.$message.warning('请检查域名格式');
				return false;
			}

			return {
				host: checkHostRes,
				params: {
					username: user,
					password: pass
				}
			};
		},

		async loginFn() {
			if (this.loginBtnLoading) return false;

			const data = this.checkDataFn();

			console.log('this.checkDataFn data', data);

			if (tools.checkTypeFn(data) !== 'Object') return false;

			localStorage.setItem('host', data.host);
			this.$store.commit('setStateDataFn', {
				host: data.host
			});

			this.loginBtnLoading = true;

			const result = await loginApis.loginApi(this, data.params);

			console.log('await loginApis.loginApi result', result);

			if (this.checkResultDatasFn(result)) {
				localStorage.setItem('systemLoginUser', data.params.username);
				this.$store.commit('setStateDataFn', {
					systemLoginUser: data.params.username
				});

				localStorage.setItem('systemLoginPass', data.params.password);

				localStorage.setItem('token', result);
				this.$store.commit('setStateDataFn', {
					token: result
				});

				if (!document.fullscreenElement) {
					document.documentElement.requestFullscreen();
				}

				this.loginBtnLoading = false;

				this.$router.replace({ path: '/' });
			} else {
				this.loginBtnLoading = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.root-page {
	display: flex;
	flex-direction: column;
	height: 100vh;

	.bg-image-wrap {
		height: 40%;
		background-image: url('../../assets/images/home-bg.png');
		background-size: cover;
		background-position: center;

		.bg-color-wrap {
			position: relative;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.6);

			.show-title {
				position: absolute;
				bottom: 40px;
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100%;

				.welcome-text {
					color: #ffffff;
					letter-spacing: 4px;
					font-size: 60px;
				}

				.title-wrap {
					margin-top: 20px;
					padding: 20px 60px;
					border: 1px solid rgba(253, 250, 207, 0.6);

					border-radius: 6px;

					.title-text {
						font-size: 50px;
						letter-spacing: 8px;
						color: var(--green);
						font-weight: bold;
					}
				}
			}
		}
	}

	.form-wrap {
		flex: 1;
		display: flex;
		justify-content: center;
		padding-top: 40px;

		.form-box {
			// width: 450px;
			width: 500px;

			.input-group-wrap {
				overflow: hidden;

				.input-wrap {
					display: flex;
					margin-bottom: 26px;
					height: 60px;
					border-radius: 4px;
					overflow: hidden;

					.icon-wrap {
						width: 60px;
						background: var(--green);

						.iconfont {
							color: #ffffff;
							font-size: 30px;

							&.icon-wangzhi {
								font-size: 34px;
							}
						}
					}

					.input-dom {
						width: 100%;
						border: none;

						::v-deep(.el-input) {
							height: 60px;
						}

						::v-deep(.el-input__inner) {
							height: 60px;
							font-size: 26px;
						}
					}
				}
			}

			.commit-btn {
				margin-top: 14px;
				width: 100%;
				height: 50px;
				border: none;
				background: var(--green);
				color: #ffffff;
				text-align: center;
				font-size: 20px;
				letter-spacing: 4px;
			}
		}
	}
}

.setting-fixed {
	position: fixed;
	right: 80px;
	bottom: 80px;
	padding: 20px;
	border-radius: 30px;
	background-color: rgba(0, 0, 0, 0.3);
	box-shadow: 5px 5px 20px #b3b3b3;
	cursor: pointer;

	.icon {
		font-size: 40px;
		color: #fff;
	}
}
</style>
