/**
 * @file 全局使用的网络接口相关
 */
import axios from 'axios'

import * as tools from '@/tools/tools.js'


import {
	Message
} from 'element-ui';



const instance = axios.create();

/**
 * @description 启动打印服务接口
 */
export async function startPrintServerApi() {
	let result;
	try {
		result = await instance({
			url: 'http://127.0.0.1:5555/startCloudPrint',
			method: 'post',
		});
	} catch (e) {
		console.log('await instance catch e', e);
		Message({
			type: 'error',
			message: `startPrintServer:${e.message}`
		});
		return false;
	}


	if (tools.objectGet(result.data, 'code') != 0) {
		Message({
			type: 'error',
			message: tools.objectGetString(result.data, 'msg')
		});
		return false;
	}
	console.log('http://127.0.0.1:5555/startCloudPrint result', result);
	return true;
}



/**
 * @description 获取打印数据接口
 * @param {number} orderGoodsId 订单商品id
 */
export async function printGoodsDataApi(that, orderGoodsId) {
	const result = await that.$fetch({
		url: '/api/Ewacc/winPrint',
		method: 'post',
		data: {
			id: orderGoodsId
		}
	});
	console.log('/api/Ewacc/winPrint result', result);

	if (that.checkNetworkSuccessFn(result)) {
		return {
			mbinfo: JSON.parse(result.data.mbinfo),
			order: result.data.order,
			CLodopRegCode: {
				companyName: tools.objectGetString(result.data, 'CLodopRegCode.companyName'),
				licenseText: tools.objectGetString(result.data, 'CLodopRegCode.licenseText'),
				company2: tools.objectGetString(result.data, 'CLodopRegCode.company2'),
				license2: tools.objectGetString(result.data, 'CLodopRegCode.license2')
			},
		}
	}
	return result;
}




/**
 * @description 获取批量打印数据
 * @param {Array} ids 订单商品id数组
 */
export async function batchPrintDataApi(that, ids) {
	const result = await that.$fetch({
		url: '/api/Ewacc/winPrintBatch',
		method: 'post',
		data: {
			id: ids.join(',')
		}
	});
	console.log('/api/Ewacc/winPrintBatch result', result);

	if (that.checkNetworkSuccessFn(result)) {

		return {
			CLodopRegCode: {
				companyName: tools.objectGetString(result.data, 'CLodopRegCode.companyName'),
				licenseText: tools.objectGetString(result.data, 'CLodopRegCode.licenseText'),
				company2: tools.objectGetString(result.data, 'CLodopRegCode.company2'),
				license2: tools.objectGetString(result.data, 'CLodopRegCode.license2')
			},
			list: tools.objectGetArray(result.data, 'list').map(i => {
				return {
					mbinfo: (() => {
						return JSON.parse(i.mbinfo);
					})(),
					order: (() => {
						const item = tools.cloneFn(i);
						delete item.mbinfo;
						return item;
					})()
				}
			})

		}

	}
	return result;
}