<template>
	<div class="root-page">
		<div class="title-wrap">
			<div class="title-text">windows分拣管理系统</div>

			<div class="menu-group-wrap">
				<div class="show-item" @click="systemSetBtnClickFn">
					<div class="icon-wrap"><i class="el-icon-setting" style="font-size: 20px; color: #fff"></i></div>
					<div class="text-wrap">设置</div>
				</div>

				<div class="show-item" @click="toggleScreenStatusFn">
					<div class="icon-wrap">
						<i class="el-icon-full-screen more-icon" style="font-size: 20px; color: #fff"></i>
					</div>
					<div class="text-wrap">{{ $store.state.isFullscreen ? '关闭' : '全屏' }}</div>
				</div>

				<div class="show-item" v-if="username">
					<div class="icon-wrap"><div class="iconfont icon-user"></div></div>
					<div class="text-wrap">{{ username }}</div>
				</div>

				<div class="show-item" @click="logoutBtnClickFn">
					<div class="icon-wrap"><div class="iconfont icon-tuichu"></div></div>
					<div class="text-wrap">退出</div>
				</div>
			</div>
		</div>

		<div class="btn-list-wrap">
			<div class="date-warp">
				<el-select v-model="dateTypeId" class="select-dom">
					<el-option
						v-for="(item, index) in dateTypeList"
						:key="index"
						:value="item.id"
						:label="item.name"
					></el-option>
				</el-select>

				<el-date-picker
					size="large"
					v-model="date"
					type="datetimerange"
					range-separator="至"
					start-placeholder="开始时间"
					end-placeholder="结束时间"
					:default-time="['00:00:00', '23:59:59']"
					value-format="yyyy-MM-dd HH:mm:ss"
					class="date-input"
				></el-date-picker>

				<el-radio-group v-model="dayType" @input="handleChangeDateType">
					<el-radio-button
						v-for="(item, index) in ['今日', '明日']"
						:key="index"
						:label="item"
					></el-radio-button>
				</el-radio-group>
			</div>
			<div class="btn-list-content">
				<!-- 
                <div class="btn-item-wrap" @click="$router.push({
                    name: 'sorting',
                    query: {
                        type: '商品分拣',
                        date: date,
                        dateType: dateTypeId
                    }
                })">
                    <div class="icon-warp">
                        <img src="../assets/images/home-btn-icon/goods-sorting.png" />
                    </div>
                    <p>商品分拣</p>
                </div> 
            
            
            
            
                <div class="btn-item-wrap" @click="$router.push({
                    name: 'sorting',
                    query: {
                        type: '用户分拣',
                        date: date,
                        dateType: dateTypeId
                    }
                })">
                    <div class="icon-warp">
                        <img src="../assets/images/home-btn-icon/user-sorting.png" />
                    </div>
                    <p>用户分拣</p>
                </div>
                -->

				<div
					class="btn-item-wrap"
					@click="
						$router.push({
							path: '/goodsSorting',
							query: {
								...getTimeDataFn(),
								dateType: dateTypeId
							}
						})
					"
				>
					<div class="icon-warp"><img src="../assets/images/home-btn-icon/goods-sorting.png" /></div>
					<p>商品分拣</p>
				</div>

				<div
					class="btn-item-wrap"
					@click="
						$router.push({
							path: '/userSorting',
							query: {
								...getTimeDataFn(),
								dateType: dateTypeId
							}
						})
					"
				>
					<div class="icon-warp"><img src="../assets/images/home-btn-icon/user-sorting.png" /></div>
					<p>用户分拣</p>
				</div>

				<div
					class="btn-item-wrap"
					@click="
						$router.push({
							path: '/orderSorting',
							query: {
								...getTimeDataFn(),
								dateType: dateTypeId
							}
						})
					"
				>
					<div class="icon-warp"><img src="../assets/images/home-btn-icon/order-sorting.png" /></div>
					<p>订单分拣</p>
				</div>

				<!-- 
                <div class="btn-item-wrap" @click="$router.push({
                    name: 'sorting',
                    query: {
                        type: '订单分拣',
                        date: date,
                        dateType: dateTypeId
                    }
                })">
                    <div class="icon-warp">
                        <img src="../assets/images/home-btn-icon/order-sorting.png" />
                    </div>
                    <p>订单分拣</p>
                </div>
                 -->

				<!-- <div class="btn-item-wrap" @click="$router.push({
                    name: 'sorting',
                    query: {
                        type: '缺货分拣',
                        date: date,
                        dateType: dateTypeId
                    }
                })">
                    <div class="icon-warp">
                        <img src="../assets/images/home-btn-icon/quehuo-sorting.png" />
                    </div>
                    <p>缺货分拣</p>
                </div> -->

				<div
					class="btn-item-wrap"
					@click="
						$router.push({
							path: '/outofstockSorting',
							query: {
								...getTimeDataFn(),
								dateType: dateTypeId
							}
						})
					"
				>
					<div class="icon-warp"><img src="../assets/images/home-btn-icon/quehuo-sorting.png" /></div>
					<p>缺货分拣</p>
				</div>
				<div
					class="btn-item-wrap"
					@click="
						$router.push({
							name: 'readySorting',
							query: {
								type: '预分拣',
								date: date,
								dateType: dateTypeId
							}
						})
					"
				>
					<div class="icon-warp"><img src="../assets/images/home-btn-icon/pre-sorting.png" /></div>
					<p>预分拣</p>
				</div>
				<!-- 
				<div
					class="btn-item-wrap"
					@click="
						$router.push({
							path: '/preSorting',
							query: {
								...getTimeDataFn(),
								dateType: dateTypeId
							}
						})
					"
				>
					<div class="icon-warp"><img src="../assets/images/home-btn-icon/quehuo-sorting.png" /></div>
					<p>预分拣2</p>
				</div> -->

				<div
					class="btn-item-wrap"
					@click="
						$router.push({
							name: 'scanSorting',
							query: {
								type: '扫码分拣',
								date: date,
								dateType: dateTypeId
							}
						})
					"
				>
					<div class="icon-warp"><img src="../assets/images/home-btn-icon/scan-sorting.png" /></div>
					<p>扫码分拣</p>
				</div>
			</div>
		</div>

		<system-set ref="systemSetRef" @commitSystemSetSuccess="systemSetSuccessFn"></system-set>
	</div>
</template>

<script>
import dayjs from 'dayjs';

import * as constants from '@/tools/constants.js';

import * as tools from '@/tools/tools.js';

// 系统设置组件
import systemSet from '@/components/set-comps/system-set.vue';

export default {
	components: {
		systemSet
	},
	data() {
		return {
			username: '',

			dayType: '今日',

			date: [],

			dateTypeId: '',

			dateTypeList: [
				{
					id: constants.timeTypeEnum.orderTime,
					name: '下单时间'
				},
				{
					id: constants.timeTypeEnum.dispTime,
					name: '配送时间'
				}
			]
		};
	},
	created() {
		console.log('home.vue created run');

		window.homevm = this;

		const startTime = dayjs()
				.startOf('date')
				.format('YYYY-MM-DD HH:mm:ss'),
			endTime = dayjs()
				.endOf('date')
				.format('YYYY-MM-DD HH:mm:ss');

		console.log('startTime, endTime', {
			startTime,
			endTime
		});

		this.date = [startTime, endTime];

		this.setPageDateTypeFn();

		this.showUserNameFn();
	},
	methods: {
		async logoutBtnClickFn() {
			try {
				await this.$confirm('确认退出登录', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				});
				this.logoutFn();
			} catch (e) {
				console.log('catch e', e);
			}
		},

		// 展示登录用户名字
		showUserNameFn() {
			const name = this.$store.state.systemLoginUser;

			this.username = name ? name : tools.assertString(localStorage.getItem('systemLoginUser'));
		},

		// 获取开始时间与结束时间数据
		getTimeDataFn() {
			const time = {};

			const date = {
				...tools.assertArray(this.date)
			};

			const startTime = tools.objectGetString(date, '0'),
				endTime = tools.objectGetString(date, '1');

			if (startTime) {
				time.startTime = startTime;
			}
			if (endTime) {
				time.endTime = endTime;
			}
			return time;
		},

		// 成功提交系统设置表单后更新页面上时间类型
		systemSetSuccessFn() {
			this.setPageDateTypeFn();
		},

		// 把系统设置里的时间类型赋值到页面上
		setPageDateTypeFn() {
			this.dateTypeId = this.$store.state.systemSet.showTimeType;
		},

		// 系统设置按钮点击;
		systemSetBtnClickFn() {
			console.log('systemSetBtnClickFn');
			this.$refs.systemSetRef.showFn();
		},

		// 切换全屏状态
		toggleScreenStatusFn() {
			if (document.fullscreenElement) {
				document.exitFullscreen();
			} else {
				document.documentElement.requestFullscreen();
			}
		},

		handleChangeDateType(type) {
			const dateTime = new Date().getTime();
			if (type === '今日') {
				this.date = [
					dayjs()
						.startOf('date')
						.format('YYYY-MM-DD HH:mm:ss'),
					dayjs()
						.endOf('date')
						.format('YYYY-MM-DD HH:mm:ss')
				];
			} else {
				this.date = [
					dayjs(dateTime + 3600 * 1000 * 24)
						.startOf('date')
						.format('YYYY-MM-DD HH:mm:ss'),
					dayjs(dateTime + 3600 * 1000 * 24)
						.endOf('date')
						.format('YYYY-MM-DD HH:mm:ss')
				];
			}
		}
	}
};
</script>
<style lang="scss" scoped>
#app {
	.root-page {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		background-image: url('../assets/images/home-bg.png');
		background-size: cover;
		background-position: center;

		.title-wrap {
			position: relative;
			height: 120px;

			.title-text {
				text-align: center;
				color: #ffffff;
				font-size: 34px;
				height: 120px;
				line-height: 120px;
			}

			.menu-group-wrap {
				position: absolute;
				top: 30px;
				right: 30px;
				display: flex;

				.show-item {
					display: flex;
					align-items: center;
					cursor: pointer;

					&:not(:first-child) {
						margin-left: 36px;
					}

					.icon-wrap {
						.iconfont {
							font-size: 20px;
							color: #ffffff;
						}
					}

					.text-wrap {
						padding-left: 4px;
						font-size: 20px;
						color: #ffffff;
					}
				}
			}
		}

		.btn-list-wrap {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			position: absolute;
			width: 800px;
			height: 500px;
			padding: 30px;
			background-color: rgba(255, 255, 255, 0.1);
			border-radius: 20px;
			box-sizing: border-box;
			left: 50%;
			top: 50%;
			margin-left: -400px;
			margin-top: -250px;

			.date-warp {
				display: flex;
				justify-content: center;
				margin-bottom: 30px;

				.select-dom {
					width: 110px;
				}

				.date-input {
					margin-right: 20px;
				}
			}

			.btn-list-content {
				display: grid;
				grid-template-columns: repeat(3, minmax(80px, 200px));
				grid-gap: 40px;
				justify-content: center;

				.btn-item-wrap {
					text-align: center;
					color: #fff;
					font-size: 30px;

					.icon-warp {
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: var(--green);
						border-radius: 30px;
						width: 90px;
						height: 90px;
						line-height: 90px;
						text-align: center;
						margin: 0 auto 10px auto;

						img {
							width: 60px;
							height: 60px;
						}
					}

					.iconfont {
						font-size: 60px;
					}
				}
			}
		}
	}
}
</style>
