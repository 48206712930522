/**
 * @file 项目里全局使用的工具辅助函数
 */


import * as constants from '@/tools/constants.js'


import * as tools from '@/tools/tools.js'



// 判断网络请求返回的数据是否成功; 成功返回true, 失败返回false
export function checkSuccessfulFn(result) {
	if (tools.objectGet(result, '$successData')) {
		return true;
	}
	return false;
}


// 处理称传来的重量数据
export function handleWeightDataFn(weightNumStr) {
	/*
		↓↓↓ s1
			'sg-00.145kg',
			'wg-00.145kg',
	*/
	const s1 = tools.assertString(weightNumStr);

	/*
		↓↓↓ s2
			'00.145'
	*/
	const s2 = s1.replace(/[a-zA-Z]/g, '');


	return tools.transNumFn(tools.toDoubleNumFn(s2));
}





// 同步 系统设置 的 localStorage、$store数据并初始化
export function syncSystemStorageStoreFn(that) {

	let data = tools.cloneFn(constants.systemSetDefData);

	const systemSetStorageData = localStorage.getItem('systemSetStorageKey');

	if (systemSetStorageData) {
		data = JSON.parse(systemSetStorageData);
	} else {
		localStorage.setItem('systemSetStorageKey', JSON.stringify(data))
	}

	that.$store.commit('setStateDataFn', {
		systemSet: data
	});

}

// 同步 分拣详情设置 的 localStorage、$store数据并初始化
export function syncSortingDetailStorageStoreFn(that) {

	let data = tools.cloneFn(constants.sortingDetailSetDefData);

	const sortingDetailSetStorageData = localStorage.getItem('sortingDetailSetStorageKey');

	if (sortingDetailSetStorageData) {
		data = JSON.parse(sortingDetailSetStorageData);
	} else {
		localStorage.setItem('sortingDetailSetStorageKey', JSON.stringify(data))
	}

	that.$store.commit('setStateDataFn', {
		sortingDetailSet: data
	});

}



/*
	检测域名是否合法 
		输入 www.baidu.com 返回 https://www.baidu.com
		输入 https://www.baidu.com 返回 https://www.baidu.com
		输入 http://www.baidu.com 返回 http://www.baidu.com
		输入 空格 返回 false
		输入 abcd 返回 false
		
		验证域名规则
			有http的直接使用, 没有http的拼接https://
*/
export function checkHostFn(host) {
	host = host.trim();
	if (host) {
		let protocolReg = /^https?:\/\//gim; // 协议头正则

		let domainReg = /^[A-z0-9\-]+\.([A-z0-9\-]*\.){0,}[A-z0-9\-]+$/gim; // 域名正则

		let protocol = 'https://'; // 默认的协议头
		if (protocolReg.test(host)) {
			protocol = host.match(protocolReg)[0];
		}

		host = host.replace(protocol, '').replace(/\//gim, '');

		let isDomain = host.match(domainReg);
		if (isDomain) {
			return `${protocol}${host}`;
		}
		return false;
	}
	return false;
}





// 当前操作商品数据
export function goodsInfoDataFn() {
	return {
		// 是否标品
		biaopin: false,

		// 用户名称
		userName: '',

		// 用户框号
		frameNum: '',

		// 商品图片
		goodsImage: '',

		// 商品名称
		goodsName: '',

		// 商品规格
		specName: '',

		// 仓库位置
		cangku: '',

		// 展示单位文字
		unitName: '',

		// 标品时是下单数量; 非标品时是下单重量
		orderNumWeight: '',

		// 标品时是已分拣数量; 非标品时是已分拣重量
		sortedNumWeight: '',

		// 当前操作分拣数据
		sortedInput: '',

		// 用户备注
		comment: '',

		// 分拣状态
		sortingState: '',

		// 商品重量
		goodsWeight: '',

	};
}




/**
 * @description 限制输入值为合法的 保留两位小数 的数字
 * @param {string | number} val 输入值
 * @return {string} 返回的是 合法的 保留两位小数 的数字
 */
export function checkDoubleNumFn(val) {
	val = `${val}`
		.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
		.replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
		.replace(/^\./g, '') // 保证第一个为数字而不是.
		.replace('.', '$#$')
		.replace(/\./g, '')
		.replace('$#$', '.')

	if (val.indexOf('.') < 0 && val !== '') {
		// 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
		val = parseFloat(val) + ''
	} else if (val.indexOf('.') >= 0) {
		val = val.replace(/^()*(\d+)\.(\d\d).*$/, '$1$2.$3')
		// 只能输入两个小数
	}
	return val;
}











/**
 * @description 生成html字符串
 */
export function createHtmlStrFn(param, order) {

	let html = `
        <div style="position: relative">
            [{__orderChunk__}]
        </div>
    `;

	const orderChunk = param.orderInfoSet.setList.map(i => {

		// 字体相关样式
		const fontStyle = param.orderInfoSet.fontBarDatas.fontBarItemList.map(ii => {
			let cssObject = {
				'fa-bold': 'font-weight: bold',
				'fa-underline': 'border-bottom: 1px solid',
				'fa-italic': 'font-style: italic'
			}
			if (Object.keys(cssObject).includes(ii)) {
				return cssObject[ii];
			}
			return '';
		}).join(';');


		// 坐标位置相关样式
		const positionStyle = `
            position: absolute;
            top: ${Math.floor(i.top * 0.126)}mm;
            left: ${Math.floor(i.left * 0.126)}mm;
        `;


		// 处理二维码
		if (i.id == 'QRCode') {
			return `
                <div style="${positionStyle}">
                    <img style="width: 80px; height: 80px" src="${order.QRCodeData}" />
                </div>
            `;
		}


		// 处理标识框号
		if (i.id == 'biaoshikuanghao') {
			// 生成标识html
			const biaoshiHtmlFn = str => [...new Set(str.split('-').filter(i => i))].map(i => `
				<div>
					${i}
				</div>
			`).join('');

			return `
		        <div style="font-size: 17px;font-weight: bold; ${positionStyle}">
					${biaoshiHtmlFn(tools.assertString(order.new_frame_num))}
		        </div>
		    `;
		}


		// 处理条形码
		if (i.id == 'barCode') {
			return `
                <div style="${positionStyle}">
                    ${order.barCodeData}
                </div>
            `;
		}

		// 处理系统简称
		if (i.id == 'systemName') {
			return `
                <div
                    style="
                        white-space: nowrap;
                        font-size: ${param.orderInfoSet.fontBarDatas.fontSize}px;
                        ${positionStyle}
                        ${fontStyle}
                    "
                >
                    ${order[i.id]}
                </div>
            `;
		}


		return `
            <div
                style="
                    white-space: nowrap;
                    font-size: ${param.orderInfoSet.fontBarDatas.fontSize}px;
                    ${positionStyle}
                    ${fontStyle}
                "
            >
                ${i.text}: ${order[i.id]}
            </div>
        `;
	}).join('');

	html = html.replace(`[{__orderChunk__}]`, orderChunk);

	return html;
}





/**
 * @description 当前运行环境是否windows打包壳
 */
export const runtimeEnvIsWinPackFn = () => navigator.userAgent.includes('com.sjwxsc.cheng');