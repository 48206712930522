import axios from 'axios'
import { Message, Loading } from 'element-ui'
import router from '@/router'
import { nextTick } from 'vue'

import * as tools from '@/tools/tools.js'

let loadingInstance = null
// 创建 axios 实例
const request = axios.create({
    timeout: 30000 // 请求超时时间
})

// 异常拦截处理器
function errorHandler(error) {
    const message = errorMessage(error)
    return Promise.reject(message)
}

function errorMessage(error) {
    if (error.data.code !== 200) {
        if (error.data.msg === '登录失效，请重新登录') {
            localStorage.removeItem('token');
            router.push({
                path: '/login'
            })
        }
        Message.error(error.data.msg)
    }
    return error.data.msg
}


// 请求拦截器
request.interceptors.request.use((config) => {
    console.log('request.interceptors.request.use run', tools.cloneFn(config));
    config.url = `${localStorage.getItem('host')}${config.url}`
    console.log('config.url', config.url);

    if (!loadingInstance) {
        loadingInstance = Loading.service({ fullscreen: true, text: '加载中...' })
    }

    // 设置请求头
    if (!config.headers['content-type']) {

        // 默认类型
        config.headers['content-type'] = 'application/json';

        // 有token时携带token
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.token = token;
            
            
            /* 
            config.data = {
                ...tools.assertObject(config.data),
                token
            }
             */

        }
    }
    // 需要返回数据
    return config
}, errorHandler)

// 响应拦截
request.interceptors.response.use(
    (response) => {
        // 处理自己的业务逻辑，比如判断 token 是否过期等等
        // 代码块
        errorMessage(response)
        // 以服务的方式调用的 Loading 需要异步关闭
        nextTick(() => {
            loadingInstance?.close()
            loadingInstance = null
        })
        return response.data
    },
    (err) => {
        if (err.response.status === 404) {
            Message.error(err.response.status);
        }
        loadingInstance?.close()
    }
);


export default request
