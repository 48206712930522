import request from '@/service/index.js'



//2.1 获取分类
export function gsGc(data) {
    return request({
        url: '/api/Ewacc/gsGc',
        method: 'post',
        data
    })
}

//4.1 获取客户列表
export function gsUser(data) {
    return request({
        url: '/api/Ewacc/gsUser',
        method: 'post',
        data
    })
}

//2.2 获取商品列表
export function gsList(data) {
    return request({
        url: '/api/Ewacc/gsList',
        method: 'post',
        data
    })
}
//2.3 获取商品列表详情
export function gsData(data) {
    return request({
        url: '/api/Ewacc/gsData',
        method: 'post',
        data
    })
}
//5.1获取缺货分拣列表
export function getQue(data) {
    return request({
        url: '/api/Ewacc/getQue',
        method: 'post',
        data
    })
}
//5.2获取缺货分拣商品详情
export function getQueD(data) {
    return request({
        url: '/api/Ewacc/getQueD',
        method: 'post',
        data
    })
}
//4.2 获取客户商品详情
export function gsUd(data) {
    return request({
        url: '/api/Ewacc/gsUd',
        method: 'post',
        data
    })
}
//4.5 获取线路
export function getIm(data) {
    return request({
        url: '/api/Ewacc/getIm',
        method: 'post',
        data
    })
}
//3.1 获取订单列表
export function gsOrder(data) {
    return request({
        url: '/api/Ewacc/gsOrder',
        method: 'post',
        data
    })
}
//3.2 获取订单商品
export function orderGoods(data) {
    return request({
        url: '/api/Ewacc/orderGoods',
        method: 'post',
        data
    })
}
//2.5 提交商品
export function orderSub(data) {
    return request({
        url: '/api/Ewacc/orderSub',
        method: 'post',
        data
    })
}
//6.1获取预分拣商品分类
export function getYuc(data) {
    return request({
        url: '/api/Ewacc/getYuc',
        method: 'post',
        data
    })
}
//6.6已预分拣列表
export function getYug(data) {
    return request({
        url: '/api/Ewacc/getYug',
        method: 'post',
        data
    })
}
//6.3获取预分拣商品详情
export function getYud(data) {
    return request({
        url: '/api/Ewacc/getYud',
        method: 'post',
        data
    })
}
//6.4 提交商品
export function yuS(data) {
    return request({
        url: '/api/Ewacc/yuS',
        method: 'post',
        data
    })
}
//6.6已预分拣列表
export function goodsYuList(data) {
    return request({
        url: '/api/Ewacc/goodsYuList',
        method: 'post',
        data
    })
}
//打印订单小票
export function printOrder(data) {
    return request({
        url: '/api/Ewacc/print',
        method: 'post',
        data
    })
}
//作废
export function discardYu(data) {
    return request({
        url: '/api/Ewacc/discardYu',
        method: 'post',
        data
    })
}
//获取配置
export function queryConfig(params) {
    return request({
        url: '/api/Ewacc/config',
        method: 'get',
        params
    })
}
//扫码分拣
export function scanFj(data) {
    return request({
        url: '/api/Ewacc/scanFj',
        method: 'post',
        data
    })
}
