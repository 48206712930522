import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


import * as constants from '@/tools/constants.js'

import * as tools from '@/tools/tools.js'



export default new Vuex.Store({
    state: {

        systemInfo: {
            displayType: '四列',
            goodsMode: '带图',
            showKeyboard: true,
            readyGoodsOnBottom: false,
            printType: '手动打印',
            goodsPosition: true,
            defaultTime: 1,
            isMadeScale: '否',
            util: '斤',
            boxWeight: 1,
            boxWeightList: [0],
            isPrint: '是'
        },

        // 域名
        host: '',


        // 当前登录系统的用户名
        systemLoginUser: '',

        // token
        token: '',
        
        // 系统设置相关
        systemSet: tools.cloneFn(constants.systemSetField),

        // 分拣详情设置
        sortingDetailSet: tools.cloneFn(constants.sortingDetailSetDefData),


        // 是否全屏状态
        isFullscreen: false,


        // 称传来的重量数据
        fromScaleWeightData: 0,

        weight: 0,
        weighInfo: {}
    },
    getters: {},
    mutations: {
        // 给 state 设置数据
		setStateDataFn(store, data) {
			Object.keys(data).forEach(key => store[key] = data[key]);
		},


        //设置系统配置
        setSystemInfo(state, data) {
            if (!data || typeof data !== 'object') return
            state.systemInfo = Object.assign({}, state.systemInfo, data)

            localStorage.setItem('systemInfo', JSON.stringify(data))
        },
        //获取系统配置
        getSystemInfo(state) {
            let systemInfo = localStorage.getItem('systemInfo')
            if (systemInfo) {
                systemInfo = JSON.parse(systemInfo || '{}')
                state.systemInfo = systemInfo
            }
        },
        setWeight(state, weight = '') {
            if (typeof weight !== 'string') {
                weight = weight.toString()
            }
            const patter = /[a-zA-Z]/g
            state.weight = parseFloat(weight.replace(patter, ''))

            console.log('state.weight', state.weight);
        },
        setWeighInfo(state, data) {
            state.weighInfo = data
        },

       
    },
    actions: {
        getSystem({ commit }) {
            commit('getSystemInfo')
        }
    },
    modules: {}
})
