import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/style/public.scss'
import '@/assets/style/reset.scss'


import * as utils from '@/tools/utils.js'

import * as apis from '@/service/apis.js';

import ElementUI from 'element-ui'
import {
	keyboardInputMixin
} from './mixin/keyBoard'

Vue.use(ElementUI)
Vue.mixin(keyboardInputMixin)
// 混入mixin相关函数
import mixinMethods from '@/tools/mixinMethods.js'

import {
	getLodop
} from '@/tools/libs/LodopFuncs.js'

Object.keys(mixinMethods).forEach(
	(methodsName) => (Vue.prototype[methodsName] = mixinMethods[methodsName])
)


window.gvm = new Vue({
	store,
	router,
	async beforeCreate() {
		console.log('main.js new Vue beforeCreate run', getLodop);
		const that = this;

		// window.chengFn 由外部称调用把称重数据传递到前端
		window.chengFn = function(weightNumStr) {
			// console.log('window.chengFn weightNumStr', weightNumStr);
			that.$store.commit('setStateDataFn', {
				fromScaleWeightData: utils.handleWeightDataFn(weightNumStr)
			});
		}

		if (utils.runtimeEnvIsWinPackFn()) {
			const result = await apis.startPrintServerApi();
			console.log('await apis.startPrintServerApi result', result);
		}


	},
	render: h => h(App)
}).$mount('#app')